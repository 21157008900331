body {
  font-size: 1.1rem;
}

.navbar-brand img.logo {
  height: 1.5rem;
}

.bg-light {
  background-color: #E8D8D8;
}

.bg-dark {
  background-color: #041624!important;
}

.breadcrumb {
  background-color: #3688d8;
  border-radius: 0;
  font-size: 0.8rem;
}

.jumbotron {
  background-color: #3688d8;
  color: #FFFFFF;
  border-radius: 0;
}

.jumbotron img.logo {
  height: 3.3rem;
}

.article h1 {
  font-size: 2.0rem;
  line-height: 1.4;
  font-weight: bold;
}
.article h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1rem;
  padding-top: 2.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 3px solid;
}
.article h3 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 1rem;
  padding-top: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: 3px double #dee2e6;
}

.article h4 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1rem;
  padding-top: 1rem;
  margin-bottom: 1rem;
}
.article p {
  font-size: 1.1rem;
  color: #333333;
  line-height: 2.0;
}

.article strong {
  color: red;
}

.article img {
  max-height: 300px;
  max-width: 75%;
}

.article .articleContent {
  margin-bottom: 3rem;
}

.article .articleDigest {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.article .articleDigest .list-group-item-action {
  text-decoration: underline;
}

.article blockquote {
  border-left: solid thick silver;
  padding-left: 1rem;
}

.article blockquote p {
  color: gray;
}

.article .linkCard {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.adCard img {
  max-width: 100%;
  height: auto;
}

.lastmod-container {
  margin-top: 0.5rem;
  color: #eee;
  font-size: 0.9rem;
}
